/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

body {
    margin: 0;
    font-family: var(--font-family-pop);
}

* {
    font-family: 'Poppins', sans-serif;
}


.main-content {
    min-height: 100vh;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: #ed5857;
    font-weight: 600;
}

.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: transparent;
}

@media only screen and (min-width:767px) and (max-width:992px) {
    html {
        font-size: 14px;
    }
}

@media only screen and (max-width:992px) {
    html {
        font-size: 10px;
    }
}

.mando {
    >div {
        background-color: #f6f4e8 !important;
        border: 1px solid #d1d1d1 !important;

        &:last-of-type {
            display: none;
        }
    }

}

.fade-in {
    opacity: 0;
    transition: opacity 2s ease-in-out;
}

.fade-in.show {
    opacity: 1;
}

.beta {
    position: relative;

    .comingSoon {
        position: absolute;
        background: #ffe5a1eb !important;
        color: black;
        top: -12px !important;
        padding: 4px 6px;
        border-radius: 8px;
        font-size: 0.7em;
        right: -23px !important;
    }
}

.masterSkeletonSide {
    grid-template-rows: 1fr 10fr;
    display: grid;
    height: 100vh;

    .progress {
        background-color: #dabeac !important;
        margin: 0;
    }
}

.masterSkeleton {
    background: #dbbfac;

    .profile {
        display: grid;
        grid-template-columns: 1fr 8fr;

        .rightSke {
            padding-top: 20px;

            .progress {
                background-color: #e0cabb;
            }

            .section {
                padding-top: 40px;

                >div {
                    &:first-of-type {
                        .progress {
                            width: 50%;
                        }
                    }
                }

                .logs {
                    background: #d3b6a4;
                    padding: 20px;
                    border-radius: 8px;
                    margin-right: 60px;

                    ngx-skeleton-loader {
                        >div {
                            &:last-of-type {

                                width: 30%;

                            }
                        }
                    }
                }
            }
        }

        .leftSke {
            padding-top: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            justify-items: center;
            align-content: center;
            flex-direction: column;
            align-self: baseline;

            .progress {
                background-color: #e0cabb;
            }

            display: grid;
            justify-content: center;

            .main {
                .progress {
                    width: 90px;
                    height: 90px;
                }
            }

            .childSke {
                display: grid;
                justify-items: center;
                height: 130px;

                .lineSke {
                    .progress {
                        width: 4px;
                        height: 60px;
                    }
                }

                .menuSke {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }

    .navOne {
        .progress {
            width: 40%;
        }
    }

    .navTwo {
        .progress {
            height: 4px;
            width: 90%;
        }
    }

    .navThree {
        .progress {
            height: 10px;
            width: 60%;
        }
    }

    .contents {
        padding: 25px;
        background: #d9beac;
        padding-top: 0;

        .cards {
            display: flex;
            gap: 10px;

            .progress {
                background: #daccc4;
            }

            .card {
                width: 50%;
                display: flex;
                background: #e6d9d1;
                padding: 15px;
                border-radius: 10px;
                margin-bottom: 20px;

                >div {
                    &:first-of-type {
                        width: 25%;
                    }

                    &:last-of-type {
                        width: 100%;
                    }
                }

                .circle {
                    height: 60px;
                    width: 60px;
                    margin: 0;
                }
            }
        }

        .quarter {
            .progress {
                width: 25%;
            }
        }

        .half {
            .progress {
                width: 50%;
            }
        }

        .thereeQuarter {
            .progress {
                width: 75%;
            }
        }

        .miniTable {
            padding: 25px;
            background: #e6d9d1;
            border-radius: 10px;
            height: 40vh;

            .tableControls {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 20px;

                .search {
                    width: 30%;
                    height: 40px;

                    .progress {
                        height: 40px;
                        background: #decec6;
                    }
                }

                .button {
                    width: 20%;
                    height: 40px;

                    .progress {
                        height: 40px;
                        background: #decec6;
                    }
                }
            }
        }

        .table {
            padding: 25px;
            background: #e6d9d1;
            border-radius: 10px;
            height: 90vh;


            .tableControls {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 20px;

                .search {
                    width: 30%;
                    height: 40px;

                    .progress {
                        height: 40px;
                        background: #decec6;
                    }
                }

                .button {
                    width: 20%;
                    height: 40px;

                    .progress {
                        height: 40px;
                        background: #decec6;
                    }
                }
            }

            .tableHeader {
                .progress {
                    background: #c5b7b0;
                }

                border-bottom: 1px solid #c5b7b0;
                margin-bottom: 20px;
            }

            .tableContents {
                .progress {
                    background: #daccc4;
                }
            }
        }
    }

    .skeNav {
        padding: 25px;
        background: #dabeac;

        .progress {
            border-radius: 8px !important;
            height: 24px !important;
            background: #e8ddd7 !important;
        }
    }
}



// Handles 
.center-modal-header {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dedede;

    h1 {
        margin: 0;
        font-size: 20px;
    }
}

.close-button {
    img {
        width: 14px;
    }
}

.iontoday {
    --background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), linear-gradient(303.78deg, #D7F0F1 -22.31%, rgba(215, 240, 241, 0) 57.27%);
    --border-radius: 20px;
    --box-shadow: none;
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    /* identical to box height */
    --color: #323343;
    font-family: var(--font-family-pop);
    text-transform: none;
    opacity: 0.85;
}

.search-feedback {
    font-size: 20px;
    padding: 50px;
    color: grey;
    font-family: 'Poppins';
}

#click-item {
    &:hover {
        cursor: pointer;
    }
}

.main-content {
    display: flex;
    width: 100%;

    .table-content {
        background: #DBC0AC;
        padding: 0;

        .bg-white {
            background: #ffffff;
            border-radius: 10px;

            // margin-top: 147px;
            // max-height: calc(100vh - 147px);
            // overflow: auto;
            .mat-elevation-z8 {
                table {
                    border-collapse: separate !important;
                    border-spacing: 0 0px !important;
                }
            }

            mat-form-field {
                &:first-child {
                    margin: 25px;
                }
            }
        }
    }

    .sidebar-content {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.sticky-Body-Nav {
    margin: 0;
    position: fixed;
    width: 100%;
    height: 78px;
    background-color: #dbbfac;
    display: flex;
    padding-left: 10px;
    align-items: center;
    top: 0;

    @media screen and (max-width:767px) {
        height: 50px;

    }

}

.bg-white {
    .search-form-filter {
        .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
            color: #9ea3a7;
        }

        .mdc-text-field--filled .mdc-floating-label--float-above {
            transform: translateY(-85%) scale(0.75);
        }

        .mat-input-underline {
            display: none;
        }

        .mdc-line-ripple::before,
        .mdc-line-ripple::after {
            border-bottom-width: 0px;
        }
    }
}

body.manager-main-login {
    .manager-main-sidebar {
        display: none;
    }

    .manager-main-content {
        max-width: 100%;
        flex: 0 0 100%;
        position: relative;
    }
}

// Input field design 
.basic-input-field {
    background-color: #f4f4f4;
    padding: 10px 15px;
    border-radius: 10px;
    border: none;
    width: 350px;
    font-family: var(--font-family-pop);

    ::placeholder {
        color: lightgrey;
    }

    &:focus {
        outline: none;
    }
}


::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #af9887;
}

.search-row {
    display: flex;
    justify-content: space-between;
    padding: 15px;
}

ion-tab-bar {
    --indicator-color: transparent;
}

.mat-mdc-tab-header {
    margin: 0;
}

.two-icons {
    display: flex;
    gap: 20px;
}

// Input fields 

// Mat label color
mat-label,
span {
    font-family: var(--font-family-pop) !important;
    color: black !important;
    font-weight: 400 !important;
    text-transform: capitalize !important;
    font-size: 0.89em !important;
}

mat-select {

    .mat-mdc-select-value {
        span {
            // color: grey !important;
            font-weight: 400 !important;
        }
    }

}



.formSize {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

//modal windows
.cdk-overlay-pane {
    height: auto !important;
}

.mat-mdc-dialog-surface {
    border-radius: 10px !important;
}

.modalFooter {
    display: flex;
    background-color: #e5edef;
    justify-content: space-between;
    padding: 15px 20px;
    align-items: center;
    position: sticky;
    bottom: 0;
    margin-top: 30px;


    >div {
        display: flex;
        gap: 20px;
    }

    p {
        margin: 0;
        font-family: 'Poppins';
        font-size: 0.9em;
    }

    .modalPrimaryBt {
        padding: 13px 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #2D7578;
        border-radius: 15px;
        color: white;
        font-weight: 500;
    }

    .modalSecondaryBt {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 13px 20px;
        border: 2px solid #2c7478;
        border-radius: 15px;
        color: #2c7478;
        font-weight: 500;
        background-color: #e4edef;
    }
}

/// input text 
.mat-mdc-input-element {
    color: black !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    text-transform: capitalize !important;
    font-size: 0.89em !important;

    &::placeholder {
        color: #8e8e8e !important;
        font-weight: 300 !important;
    }
}


// dropdown list
.mdc-menu-surface {
    border-radius: 8px !important;
    padding: 10px !important;
    box-shadow: 0px 10px 30px rgb(0 0 0 / 20%);
}


// fields gaps

.example-form {
    padding: 0 20px 15px 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    >div {
        display: flex;
        gap: 15px;
    }
}

// position

.mat-mdc-floating-label {
    transform: translateY(-85%) scale(0.80) !important;
}

// end

// border radius for input fields
.mdc-text-field {
    border-radius: 8px !important;
}

mat-form-field {
    border-radius: 8px !important;
}

mat-mdc-form-field-focus-overlay {
    border-radius: 8px !important;
}

.hit {
    cursor: pointer;
}

.range {
    position: absolute;
    right: 20px;
    top: 10px;

    .iontoday {
        height: 44px !important;
        font-size: 0.8em !important;
        font-family: 'Poppins' !important;
        --background: black !important;
        color: white !important;
        width: 120px !important;

        .button-native {
            border-radius: 20px !important;
            display: flex;
            gap: 10px;
        }

        button {
            border-radius: 20px !important;
        }

        p {
            padding-left: 10px;
        }
    }

    .mat-mdc-form-field-infix {
        min-height: 44px;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
    }

    .mdc-text-field {
        border-radius: 20px !important;
        background: #f5f5f54d;
    }

    .mat-date-range-input-wrapper {
        padding-right: 10px;
    }
}


// end

//on focus
.mat-mdc-form-field-focus-overlay {
    background-color: white !important;
    border: 2px solid black !important;
    border-radius: 8px !important;
}

.cdk-overlay-pane {
    mat-option {
        border-radius: 8px !important;
        margin-bottom: 5px;
    }
}

.mat-mdc-select-arrow {
    // opacity: 0.3;
}

.UIArrow {
    background-image: url('/assets/dropdownArrow.svg');

}

.mdc-tab__text-label {
    display: inline-flex;
    align-items: center;
    font-family: 'Poppins' !important;
    font-weight: 500 !important;
    font-size: 13px !important;
}

.mat-mdc-cell {
    text-transform: capitalize !important;
}


.inputRow {
    display: flex;
    gap: 15px;
    width: 100%;

    .inputExtended {
        display: flex;

        mat-form-field {
            &:first-of-type {
                border-radius: 8px 0px 0px 8px !important;
            }

            &:last-of-type {
                border-radius: 0px 8px 8px 0px !important;
            }
        }



    }
}

.newPetTextBt {
    color: #3f7f83;
    font-family: 'Poppins';
    font-weight: 600;
    text-decoration: underline;
}

// Interactive buttons
.yellowBt {
    background-color: #FFDB45;
    box-shadow: 0px 0px 0px 5px rgb(251 245 206);
    padding: 10px 15px;
    border-radius: 25px;
    transition: box-shadow 100ms linear;

    &:hover {
        box-shadow: 0px 0px 0px 8px rgb(251 245 206);
        background-color: #f8df79;
        cursor: pointer;

    }
}

// .add-treatment-popup .two-icons{
//     display: block;
// }